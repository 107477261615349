// Input Button + Forms
//

// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         0.75rem;
$input-btn-padding-x:         1.5625rem;
$input-btn-font-size:         $h6-font-size;
$input-btn-line-height:       1.88;

$input-btn-focus-width:       .2rem;
$input-btn-focus-color:       rgba($primary, .25);
$input-btn-focus-box-shadow:  0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm:      .25rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $font-size-sm;
$input-btn-line-height-sm:    1.2;

$input-btn-padding-y-lg:      .5rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $font-size-lg;
// $input-btn-line-height-lg:    $line-height-lg;

// $input-btn-border-width:      $border-width;


$input-border-radius:         0.3125rem; //8px
// $input-border-radius-lg:                $border-radius-lg;
// $input-border-radius-sm:                $border-radius-sm;

$form-group-margin-bottom: 1.25rem;
$input-height:             4.375rem; //70px
$label-margin-bottom : 0.9375rem;
$input-color: gray;
$custom-control-indicator-active-bg : $primary;
$input-placeholder-color: rgba(37, 55, 63, 0.7);
$input-border-color: rgba(128, 138, 142, 0.2);
$input-font-weight : 400;
$input-font-size: 1rem;
$input-padding-x:  0.9375rem;


// $input-height-sm:                       add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) !default;
$input-height-lg:                       4.4375rem;
$input-padding-x-lg: 1.5625rem;
$input-font-size-lg: 1.125rem;
$input-padding-x-lg: 1.5625rem;



// $input-focus-bg:                        $input-bg !default;
$input-focus-border-color:              $input-border-color;
$input-focus-color:                     $input-color !default;
$input-focus-width:                     $input-btn-focus-width !default;
$input-focus-box-shadow:                0;

$form-check-input-checked-bg-color : $primary
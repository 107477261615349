$white              :#fff;
$black              :#000;
$caribbean-green : #00BB98;
$bright-sun : #FFC83E;
$blue-ribbon : #2B59FF;
$stratos : #000B33;
$carnation : #FD4C5C;
$electric-violet : #781BFF;
$resolution-blue : #001C80;
$dodger-blue : #001C80;
$dodger-blue-2: #377CFD;



$header-link-color: $stratos;
$btn-link-color: $stratos;